<template>
  <div
    class="modal fade"
    id="modalPO"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Barang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Nama Barang </label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="formDetail.nama_barang"
                  disabled
                />
                <!-- <Select2
                  v-model="formDetail.kode_barang"
                  :options="optionGoods"
                  placeholder="Pilih barang"
                  :settings="{ templateResult: resultBarang }"
                  @change="changeBarang('kode_barang', $event)"
                  @select="changeBarang('kode_barang', $event)"
                  :class="{
                    'is-invalid':
                      formErrorDetail && formErrorDetail.kode_barang,
                  }"
                /> -->
              </div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label class="form-label">Jumlah </label>
              <div class="row">
                <div class="col-6" style="padding-right: 0px">
                  <input
                    type="text"
                    class="form-control text-right"
                    placeholder="Jumlah"
                    v-model="formDetail.qty"
                    @keyup="changeBarang('qty')"
                    v-maska="'#############'"
                    :class="{
                      'is-invalid': formErrorDetail && formErrorDetail.qty,
                    }"
                  />
                </div>
                <div class="col-6">
                  <Select2
                    v-model="formDetail.id_satuan"
                    :options="optionUnit"
                    disabled
                    placeholder="Satuan"
                  />
                </div>
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Harga Satuan </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Rp</span>
                </div>
                <input
                  type="text"
                  class="form-control text-right"
                  placeholder="masukkan harga satuan"
                  v-model.lazy="formDetail.harga_satuan"
                  @keyup="changeBarang('harga_satuan')"
                  v-money="configMoney"
                  :class="{
                    'is-invalid':
                      formErrorDetail && formErrorDetail.harga_satuan,
                  }"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="saveBarang" class="btn btn-save">
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Edit Purchases Order</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-PO">Purchases Order </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Edit
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button
                class="btn-add mr-2"
                type="button"
                @click="editable"
                v-show="validated == 1 && formData.status !== 'selesai'"
                style="padding: 10px 12px"
              >
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>

              <button
                type="button"
                @click="setApprovePO"
                v-if="
                  formData.status == 'menunggu persetujuan' &&
                  checkModuleAccess('inventory', 'cru_approval_po') &&
                  validated != 1
                "
                :disabled="onApprove"
                class="btn btn-edit mr-2"
              >
                <span class="fas fa-spinner fa-spin" v-if="onApprove"></span>
                Setujui PO
              </button>
              <button
                class="btn btn-save"
                v-if="formData.status !== 'selesai' && validated != 1"
                :disabled="isSubmit || onApprove"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card-table info-detail">
            <div class="row mt-2">
              <div class="col-12">
                <div class="title">Edit Purchases Order</div>
                <div class="sub-title">Edit informasi yang diperlukan</div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Nomor PO:</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nomor PO"
                    disabled
                    v-model="formData.kode"
                  />
                </div>

                <div class="form-group">
                  <label>Supplier :</label>
                  <Select2
                    :disabled="validated == 1"
                    v-model="formData.id_vendor"
                    :options="optionVendor"
                    placeholder="Pilih Supplier"
                    @change="formChange('id_vendor')"
                    @select="formChange('id_vendor')"
                    :class="{
                      'is-invalid': formError && formError.id_vendor,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_vendor"
                  >
                    {{ formError.id_vendor }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Departemen :</label>
                  <Select2
                    :disabled="true"
                    v-model="formData.id_divisi"
                    :options="optionDepartement"
                    placeholder="Pilih Departemen"
                    @change="formChange('id_divisi')"
                    @select="formChange('id_divisi')"
                    :class="{
                      'is-invalid': formError && formError.id_divisi,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_divisi"
                  >
                    {{ formError.id_divisi }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Jenis PO :</label>
                  <Select2
                    :disabled="true"
                    v-model="formData.jenis"
                    :options="optionTypePO"
                    placeholder="Pilih Jenis PO"
                    @change="formChange('jenis')"
                    @select="formChange('jenis')"
                    :class="{
                      'is-invalid': formError && formError.jenis,
                    }"
                  />
                  <div class="form-error" v-if="formError && formError.jenis">
                    {{ formError.jenis }}
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label>Akun Hutang :</label>
                  <Select2
                    :disabled="validated == 1"
                    v-model="formData.account_payble"
                    :options="optionAccountPayble"
                    placeholder="Pilih Akun Hutang"
                    @change="formChange('account_payble')"
                    @select="formChange('account_payble')"
                    :class="{
                      'is-invalid': formError && formError.account_payble,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.account_payble"
                  >
                    {{ formError.account_payble }}
                  </div>
                </div> -->
              </div>

              <div class="col-6">
                <div
                  class="form-group"
                  v-if="
                    (formData.status == 'menunggu persetujuan' &&
                      checkModuleAccess('inventory', 'cru_approval_po')) ||
                    formData.status != 'menunggu persetujuan'
                  "
                >
                  <label>Tanggal PO :</label>
                  <Datepicker
                    :disabled="validated == 1"
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal_po"
                    placeholder="Tanggal PO"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.tanggal_po,
                    }"
                    @update:modelValue="changeDate($event, 'tanggal_po')"
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.tanggal_po"
                  >
                    {{ formError.tanggal_po }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Tanggal Request Beli :</label>
                  <Datepicker
                    :disabled="validated == 1"
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal_req_beli"
                    placeholder="Tanggal Request Beli"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.tanggal_req_beli,
                    }"
                    @update:modelValue="changeDate($event, 'tanggal_req_beli')"
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.tanggal_req_beli"
                  >
                    {{ formError.tanggal_req_beli }}
                  </div>
                </div>

                <div class="form-group">
                  <label class="" for="inlineFormInputGroupUsername"
                    >Keterangan :</label
                  >
                  <textarea
                    :disabled="validated == 1"
                    type="text"
                    class="form-control"
                    v-model="formData.keterangan"
                    placeholder="Keterangan"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>File</label>
                  <fileUpload
                    :disabled="validated == 1"
                    :file="listFile"
                    @update="setFile"
                    @delete="setDelete"
                  />
                </div>
              </div>

            </div>

            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="pills-rincian-tab"
                  data-toggle="pill"
                  href="#pills-rincian"
                  role="tab"
                  aria-controls="pills-rincian"
                  aria-selected="true"
                  >Rincian Barang</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  >Payment Order</a
                >
              </li>
            </ul>
            <hr />
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-rincian"
                role="tabpanel"
                aria-labelledby="pills-rincian-tab"
              >
                <div class="row">
                  <div class="col-12 text-right">
                    <button
                      class="btn btn-add"
                      type="button"
                      v-if="
                        formData.status !== 'selesai' &&
                        formData.status != 'menunggu persetujuan' &&
                        formData.status != 'menunggu pembayaran' &&
                        formData.status != 'proses pembayaran'
                      "
                      @click="createReception"
                    >
                      Penerimaan
                    </button>
                  </div>
                </div>
                <table
                  class="table table-bordered table-striped table-hover mt-3"
                  id="tablePO"
                  v-if="
                    formData.status != 'menunggu persetujuan' ||
                    !checkModuleAccess('inventory', 'cru_approval_po')
                  "
                >
                  <thead>
                    <tr>
                      <th style="width: 20%">Kode</th>
                      <th style="width: 30%">Nama Barang</th>
                      <th style="width: 20%">Harga Satuan</th>
                      <th style="width: 10%">Qty PO</th>
                      <th style="width: 10%">Qty Diterima</th>
                      <th style="width: 10%">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, index) in listDetail" :key="index">
                      <td>{{ value.kode_barang }}</td>
                      <td>{{ value.nama_barang }}</td>
                      <td class="text-right">
                        {{ formatMoney(value.harga_satuan) }}
                      </td>
                      <td class="text-right">
                        {{ `${value.qty} ${value.nama_satuan}` }}
                      </td>
                      <td class="text-right">
                        {{
                          `${value.qty_terima ? value.qty_terima : 0} ${
                            value.nama_satuan
                          }`
                        }}
                      </td>
                      <td class="py-2">
                        <div
                          class="orange-bedge"
                          v-if="value.status == 'diproses'"
                        >
                          Diproses
                        </div>
                        <div
                          class="orange-bedge"
                          v-else-if="value.status == 'diterima sebagian'"
                        >
                          Diterima Sebagian
                        </div>
                        <div class="green-bedge" v-else>Diterima</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  v-else
                  class="table table-bordered table-striped table-hover"
                  id="tablePO"
                >
                  <thead>
                    <tr>
                      <th style="width: 10%">Kode</th>
                      <th style="width: 20%">Nama Barang</th>
                      <th style="width: 15%">Qty</th>
                      <th style="width: 10%">Harga Satuan</th>
                      <th style="width: 10%">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, index) in listDetail" :key="index">
                      <td @click="editItem(key, value)">
                        {{ value.kode_barang }}
                      </td>
                      <td @click="editItem(key, value)">
                        {{ value.nama_barang }}
                      </td>
                      <td @click="editItem(key, value)">
                        {{ value.qty }} {{ value.nama_satuan }}
                      </td>
                      <td @click="editItem(key, value)" class="text-right">
                        {{ value.harga_satuan }}
                      </td>
                      <td
                        @click="editItem(key, value)"
                        class="text-right position-relative"
                      >
                        {{
                          countHarga(value.harga_satuan, parseInt(value.qty))
                        }}

                        <!-- <div
                            style="
                              position: absolute;
                              right: -30px;
                              top: 50%;
                              transform: translateY(-50%);
                            "
                          >
                            <img
                              src="/img/avatar/icon-delete.svg"
                              @click="deleteRow(index)"
                              class="btn-add-row ml-1"
                              style="cursor: pointer; width: 20px"
                            />
                          </div> -->
                      </td>
                    </tr>
                    <tr v-if="listDetail.length == 0">
                      <td colspan="5" class="text-center">Tidak ada data</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div class="row">
                  <div class="col-12 text-right">
                    <button
                      class="btn btn-toska"
                      type="button"
                      @click="detailPayment('')"
                      v-if="
                        formData.status != 'menunggu persetujuan' &&
                        !formData.id_hutang
                      "
                    >
                      + Payment Order
                    </button>
                  </div>
                </div>
                <table
                  class="table table-bordered table-striped table-hover mt-3"
                  id="tablePO"
                >
                  <thead>
                    <tr>
                      <th style="width: 20%">Nomor</th>
                      <th style="width: 30%">Jatuh Tempo</th>
                      <th style="width: 20%">Jumlah</th>
                      <th style="width: 10%">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-on:click="detailPayment(value.id)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Klik untuk melihat detail"
                      v-for="(value, index) in listPayment"
                      :key="index"
                    >
                      <td>{{ value.id }}</td>
                      <td>{{ formatDate(value.jatuh_tempo) }}</td>
                      <td class="text-right">
                        {{
                          formatMoney(
                            value.nilai -
                              Math.ceil(
                                (value.nilai * value.potongan_hutang) / 100
                              )
                          )
                        }}
                      </td>

                      <td class="py-2">
                        <div
                          v-if="value.status_pembayaran == 'paid'"
                          class="green-bedge"
                        >
                          Paid
                        </div>
                        <div v-else class="red-bedge text-capitalize">
                          {{ value.status_pembayaran }}
                        </div>
                      </td>
                    </tr>
                    <tr v-if="listPayment.length == 0">
                      <td class="text-center" colspan="4">Tidak ada data</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

import {
  checkModuleAccess,
  checkRules,
  cksClient,
  showAlert,
  checkFileOrImageFromLink,
} from "../../../../helper";
import { get_ListDivisi, get_SatuanList } from "../../../../actions/master";
import { get_ListVendor } from "../../../../actions/vendor";
import { get_BarangList } from "../../../../actions/barang";
import {
  get_PurchaseOrderDetail,
  post_PurchaseOrderSave,
  get_PriceGoodsSupplier,
} from "../../../../actions/barang/purchaseOrder";
import moment from "moment";
import "moment/locale/id";

import { maska } from "maska";
import { VMoney } from "v-money";
import fileUpload from "../../components/fileUpload.vue";
import { DATA } from '../../../../constans/Url';

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    fileUpload
    // TableLite
  },

  directives: {
    money: VMoney,
    maska,
  },

  data() {
    return {
      validated: 1,
      optionVendor: [],
      optionDepartement: [],
      optionGoods: [],
      id_company: cksClient().get("_account").id_company,
      accessDepartement: cksClient().get("_account").akses_departement,
      optionUnit: [],
      optionTypePO: [
        {
          id: "sewa",
          text: "Sewa",
        },
        {
          id: "pembelian",
          text: "Pembelian",
        },
      ],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      //FORMDATA
      kode: this.$route.params.kode ? this.$route.params.kode : "",
      tanggal_po: "",
      isSubmit: false,
      listFile: [],
      fileDelete: [],
      formData: {
        kode: this.$route.params.kode ? this.$route.params.kode : "",
        id_company: cksClient().get("_account").id_company,
        id_karyawan: cksClient().get("_account").id,
        id_vendor: "",
        id_divisi: "",
        tanggal_req_beli: "",
        tanggal_po: "",
        keterangan: "",
        jenis: "",
        status: "aktif",
        id_hutang: "",
      },
      formError: [],
      rules: {
        id_vendor: {
          required: false,
        },
        id_divisi: {
          required: false,
        },
        tanggal_req_beli: {
          required: true,
        },
        tanggal_po: {
          required: false,
        },
        keterangan: {
          required: false,
        },
        jenis: {
          required: true,
        },
      },
      listDetail: [],
      listPayment: [],
      checkModuleAccess: checkModuleAccess,
      onApprove: false,
      formDetail: {
        key: "",
        kode_barang: "",
        nama_barang: "",
        qty: "",
        harga_satuan: "",
        nama_satuan: "",
        id_satuan: "",
        harga_pagu: "",
      },
      formErrorDetail: [],
      rulesDetail: {
        kode_barang: {
          required: true,
        },
        nama_barang: {
          required: true,
        },
        qty: {
          min: 1,
          required: true,
          msg_min: "this field is required",
        },
        harga_satuan: {
          required: true,
          min: 1,
          msg_min: "this field is required",
        },
        nama_satuan: {
          required: true,
        },
        id_satuan: {
          required: true,
        },
      },
      onGetPrice: false,
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    // console.log(this.checkModuleAccess("inventory", "cru_approval_po"));
    // $("#tablePO").DataTable({
    //   info: false,
    // });

    // $("#tableHsitory").DataTable({
    //   info: false,
    // });
    this.tooltip();
  },

  created() {
    this.getBarang();
    this.getDivisi();
    this.getVendor();
    this.getSatuan();
    this.getData();
  },

  methods: {
    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },
    getPriceVendor() {
      this.onGetPrice = true;
      get_PriceGoodsSupplier(
        {
          id_vendor: this.formData.id_vendor,
          kode_barang: this.formDetail.kode_barang,
        },
        (res) => {
          this.onGetPrice = false;
          this.formDetail.harga_satuan = res.harga;
        },
        () => {
          this.onGetPrice = false;
          this.formDetail.harga_satuan = 0;
        }
      );
    },
    editable() {
      this.validated = 2;
    },

    setFile(e) {
      this.listFile = e;
    },
    setDelete(e) {
      if (e) {
        this.fileDelete.push(e);
      }
    },

    detailPayment(id = "") {
      localStorage.setItem("_inventory", true);
      var params = {};
      if (!id) {
        params = {
          query: {
            kode_po: this.kode,
          },
        };
      } else {
        params = {
          params: {
            id_hutang: id,
          },
        };
      }
      this.$router.push({
        name: id ? "DetailPaymentOrder" : "InsertPaymentOrder",
        ...params,
      });
      $('[data-toggle="tooltip"]').tooltip("hide");
    },
    createReception() {
      this.$router.push({
        name: "InsertReception",
        params: {
          kode_po: this.kode,
        },
      });
    },
    getData() {
      get_PurchaseOrderDetail(this.kode, (res) => {
        var { data, detail, paymentOrder, files } = res;
        delete data.created_at;
        delete data.updated_at;
        data.tanggal_req_beli = data.tanggal_req_beli
          ? moment(data.tanggal_req_beli).format("YYYY-MM-DD")
          : "";
        data.tanggal_po =
          data.tanggal_po && data.tanggal_po != "null"
            ? moment(data.tanggal_po).format("YYYY-MM-DD")
            : "";
        this.tanggal_po = data.tanggal_po;
        this.formData = data;
        this.listDetail = detail;
        this.listPayment = paymentOrder;
        for (const key in this.listDetail) {
          if (Object.hasOwnProperty.call(this.listDetail, key)) {
            this.listDetail[key].harga_satuan = this.formatMoney(
              this.listDetail[key].harga_satuan
            );
          }
        }
        
        this.listFile = []
        for (const key in files) {
            if (Object.hasOwnProperty.call(files, key)) {
              const element = files[key];
              var type = checkFileOrImageFromLink(element.nama_file);
              this.listFile.push({
                id: element.session,
                preview: type == 'file' ? '/img/icon_documents.png' : DATA.BARANG(element.nama_file),
                link_preview: DATA.BARANG(element.nama_file),
                file: null,
                type: checkFileOrImageFromLink(element.nama_file)
              });
            }
          }
      });
    },
    //master
    countHarga(harga_satuan, qty) {
      var harga = parseInt(harga_satuan.toString().split(".").join(""))
        ? parseInt(harga_satuan.toString().split(".").join(""))
        : 0;
      return this.formatMoney(harga * qty);
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.optionDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionDepartement.push(element);
          }
        }
      });
    },
    getSatuan() {
      get_SatuanList(
        (res) => {
          var { list } = res;
          this.optionUnit = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionUnit.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        },
        (e) => {
          console.log(e);
        }
      );
    },
    async getBarang() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      await get_BarangList(
        {
          id_company: this.id_company,
          search: "aktif",
          id_divisi: id_divisi,
        },
        (res) => {
          var { list } = res;
          this.optionGoods = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionGoods.push({
                text: element.nama,
                id: element.kode,
                satuan: element.satuan,
                nama_satuan: element.nama_satuan,
                nama_kategori: element.nama_kategori,
                pagu: element.harga_pagu,
              });
            }
          }
        },
        () => {
          this.list = [];
        }
      );
    },
    getVendor() {
      get_ListVendor(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionVendor = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionVendor.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },
    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },

    postData() {
      var data = {...this.formData};
      data.detail = [];

      var dataForm = new FormData();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key];
          if (key == "detail") {
            element.forEach((obj, index) => {
              Object.entries(obj).forEach(([i, value]) => {
                dataForm.append(`${key}[${index}][${i}]`, value);
              });
            });
          } else{
            dataForm.append(key, element);
          }
        }
      }
      // this.formData.files = [];
      for (const key in this.listFile) {
        if (Object.hasOwnProperty.call(this.listFile, key)) {
          const element = this.listFile[key];
          if (element.file) {
            dataForm.append(`files[]`, element.file);
          }
        }
      }
      for (const key in this.fileDelete) {
        if (Object.hasOwnProperty.call(this.fileDelete, key)) {
          const element = this.fileDelete[key];
          dataForm.append(`deletefile[]`, element);
        }
      }

      this.isSubmit = true;
      post_PurchaseOrderSave(
        dataForm,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data Purchase order berhasil dicatat"
              : "Data Purchase order berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DataPO",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },

    async onSubmit() {
      this.rules.tanggal_po.required = false;
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.kode
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else {
        if (this.listDetail.length === 0) {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Masukan detail Purchases Order terlebih dahulu",
            showConfirmButton: false,
          });
        }
      }
    },

    //formDetail
    editItem(key, value) {
      this.formDetail.key = key;
      this.formDetail.kode_barang = value.kode_barang;
      this.formDetail.nama_barang = value.nama_barang;
      this.formDetail.qty = value.qty;
      this.formDetail.harga_satuan = this.formatMoney(value.harga_satuan);
      this.formDetail.nama_satuan = value.nama_satuan;
      this.formDetail.id_satuan = value.id_satuan;
      $("#modalPO").modal("show");
    },

    async saveBarang() {
      for (const key in this.rulesDetail) {
        if (Object.hasOwnProperty.call(this.rulesDetail, key)) {
          this.rulesDetail[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesDetail, this.formDetail);
      this.formErrorDetail = check.error;
      if (check.success) {
        this.listDetail.splice(this.formDetail.key, 1);
        this.listDetail.push({
          kode_barang: this.formDetail.kode_barang,
          nama_barang: this.formDetail.nama_barang,
          qty: this.formDetail.qty,
          harga_satuan: this.formDetail.harga_satuan,
          nama_satuan: this.formDetail.nama_satuan,
          id_satuan: this.formDetail.id_satuan,
        });
        $("#modalPO").modal("hide");
      }
    },

    // deleteRow(index) {
    //   this.listDetail.splice(index, 1);
    // },

    async checkDetail() {
      var check = true;
      for (const key in this.listDetail) {
        if (Object.hasOwnProperty.call(this.listDetail, key)) {
          const element = this.listDetail[key];
          console.log(element.harga_satuan);
          if (!element.harga_satuan || element.harga_satuan == 0) {
            check = false;
            break;
          }
        }
      }
      return check;
    },

    async changeBarang(key, val) {
      if (this.rulesDetail[key]) {
        this.rulesDetail[key].changed = true;
      }
      if (key === "kode_barang") {
        this.formDetail.nama_barang = val.text;
        this.formDetail.nama_satuan = val.nama_satuan;
        this.formDetail.id_satuan = val.satuan;
        // this.formBarang.id_kategori = val.kategori;
      }
      var check = await checkRules(this.rulesDetail, this.formDetail);
      this.formErrorDetail = check.error;
    },

    postApprove() {
      var data = { ...this.formData };
      data.detail = this.listDetail;
      data.status = "diproses";
      this.onApprove = true;
      post_PurchaseOrderSave(
        data,
        (res) => {
          this.onApprove = false;
          var msg =
            res.response_code === 201
              ? "Data Purchase order berhasil disetujui"
              : "Data Purchase order berhasil disetujui";
          this.getData();
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
          });
        },
        () => {
          this.onApprove = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },

    async setApprovePO() {
      var checkDetail = await this.checkDetail();
      this.rules.tanggal_po.required = true;
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success && checkDetail) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin akan menyetujui data PO ini?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postApprove();
          },
        });
      } else {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Masukan Tanggal PO dan harga satuan terlebih dahulu, sebelum menyetujui data Purchase order ini",
        });
      }
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
